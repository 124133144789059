var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-wrapper" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showChatroom
          ? _c("div", { staticClass: "chat-container" }, [
              _c("div", { attrs: { id: "user_info" } }, [
                _c("div", { staticClass: "user-profile" }, [
                  _c("div", { staticClass: "user-image" }, [
                    _c("img", {
                      key: _vm.imageURL,
                      attrs: { src: _vm.imageURL },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "user-name",
                      staticStyle: { "font-family": "system-ui" },
                    },
                    [_vm._v(_vm._s(_vm.chosenUsername))]
                  ),
                ]),
              ]),
              _c(
                "section",
                { ref: "chatArea", staticClass: "chat_area" },
                _vm._l(_vm.messages, function (message, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class:
                        message.sender === _vm.loggedInUserId
                          ? "sent"
                          : "received",
                    },
                    [
                      _c("div", { staticClass: "message-bubble" }, [
                        _vm._v(" " + _vm._s(message.message) + " "),
                        _c("div", { staticClass: "timestamp" }, [
                          _vm._v(
                            _vm._s(_vm.formatTimestamp(message.createdAt))
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "section",
                { ref: "sendSection", staticClass: "send_section" },
                [
                  _c(
                    "form",
                    {
                      staticClass: "message-form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.sendMessage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newMessage,
                            expression: "newMessage",
                          },
                        ],
                        staticClass: "message-input",
                        staticStyle: {
                          outline: "none",
                          "font-size": "22px",
                          "padding-left": "20px",
                        },
                        attrs: {
                          id: "user_input",
                          type: "text",
                          placeholder: "Type your message",
                        },
                        domProps: { value: _vm.newMessage },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newMessage = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "send-button",
                          attrs: { id: "submit_button", type: "submit" },
                        },
                        [_c("i", { staticClass: "fas fa-paper-plane" })]
                      ),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      !_vm.showChatroom
        ? _c("div", { staticClass: "Welcome-text" }, [
            _c("div", { staticClass: "content-wrapper" }, [
              _c("div", { staticClass: "hello-text" }, [
                _vm._v(
                  "WELCOME TO TALKLINK " + _vm._s(_vm.loggedInUserId.user_name)
                ),
              ]),
              _c("h2", { staticClass: "slogan" }, [
                _vm._v("🌍 Speak Global, 🗨️ Chat Local."),
              ]),
              _c("h3", { staticClass: "info" }, [
                _vm._v("Connecting worlds, one chat at a time."),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }