// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#navbar {
  width: 100%;
  background-color: white;
  align-content: center;
}
/* Add a black background color to the top navigation */
.topnav {
  width: 100%;
  background-color: transparent;
  display: flex;
    align-items: center; /* This will vertically center the children */
    justify-content: space-between; /* This will give space between .icon-user and the dropdown */
}
.topnav p {
  float: left;
  color: black;
  text-decoration: none;
  font-size: 26px;
}
.topnav i {
  float: left;
  margin: 5px;
}
.popup-content{
position: relative;
}
.popup {
  position: fixed; /* Fixed position for the popup */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the element */
  width: 22%;
  height: 20%;
  background-color: whitesmoke;
  justify-content: center;
  display: flex;
  z-index: 2; /* Ensure the popup is above other content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a slight shadow for the popup */
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); /* Apply a blur to the background */
  -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
  border-radius: 40px;
}
.popup form {
  text-align: center;
}
.popup h2 {
  margin-top: 10px;
  font-size: 24px;
}
.popup label {
  font-size: 16px;
  color: black;
  margin-top: 12px;
}
.popup input {
  margin-top: 5px;
  padding: 8px;
  width: 80%;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 14px;
}
.popup button:hover {
  background-color: darkslategray;
}
.popup button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: black;
  color: whitesmoke;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.icon-user {
    display: flex;
    align-items: left;
    justify-content: space-between;
    position: relative; color: black;
    font-size: 30px;
}
.icon-settings {
    display: flex;
    align-items: right;
    justify-content: space-between;
    position: relative;
    font-size: 30px;
    padding: 0;
    border: none;
    background: none;
}
/* Remove border, padding, background, and shadows from the dropdown button */
.icon-settings .btn {
    padding: 0;
    border: none;
    background: none;
    box-shadow: none;
    display: flex;
    align-items: center; /* Align items to the center for vertical centering */
    color: black;
}

/* Remove border and shadow from the dropdown menu */
.icon-settings .dropdown-menu {
    border: none;
    box-shadow: none;
}
.icon-settings .btn:hover {
    background-color:black;
}
.popup .fa-xmark {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.fa-xmark {
  padding: 5px;
}
#put{
  position: fixed; /* Fixed position for the popup */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the element */
  width: 22%;
  height: 35%;
  background-color: whitesmoke;
  justify-content: center;
  display: flex;
  z-index: 2; /* Ensure the popup is above other content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a slight shadow for the popup */
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); /* Apply a blur to the background */
  -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
  border-radius: 40px;
}
@media only screen and (max-width: 1300px){
.popup label {
  font-size: 16px;
  color: black;
  margin-top: 12px;
}
@media only screen and (max-width: 968px){
.popup label {
font-size: 11px;
color: black;
margin-top: 12px;
}
#put {
height: 40%;
width: 30%;
display: block;
}
#put{
  position: fixed; /* Fixed position for the popup */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the element */
  width: 22%;
  height: 25%;
  background-color: whitesmoke;
  justify-content: center;
  display: flex;
  z-index: 2; /* Ensure the popup is above other content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a slight shadow for the popup */
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); /* Apply a blur to the background */
  -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
  border-radius: 40px;
}
@media only screen and (max-width: 768px) {
.popup {
  position: fixed; /* Fixed position for the popup */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the element */
  width: 75%;
  height: 25%;
  background-color: whitesmoke;
  justify-content: center;
  display: flex;
  z-index: 2; /* Ensure the popup is above other content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a slight shadow for the popup */
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); /* Apply a blur to the background */
  -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
  border-radius: 40px;
}
.popup form {
  text-align: center;
}
.popup h2 {
  margin-top: 10px;
  font-size: 24px;
}
.popup label {
  font-size: 12px;
  color: black;
  margin-top: 12px;
}
.popup input {
  margin-top: 5px;
  padding: 8px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}
.popup button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: black;
  color: whitesmoke;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.popup button:hover {
  background-color: darkslategray;
}
p{
  display: none;
}
.user{
  display: none;
}
}
}
#put {
  position: fixed; /* Fixed position for the popup */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the element */
  width: 40%;
  height: 45%;
  background-color: whitesmoke;
  justify-content: center;
  display: flex;
  z-index: 2; /* Ensure the popup is above other content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a slight shadow for the popup */
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); /* Apply a blur to the background */
  -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
  border-radius: 40px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
