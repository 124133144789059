var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Bar-layout" },
    [
      _c("div", { staticClass: "search-bar" }, [
        _c(
          "div",
          {
            staticClass: "input-wrapper",
            staticStyle: { border: "none", "font-family": "system-ui" },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery",
                },
              ],
              attrs: { type: "text", placeholder: "Search for a user" },
              domProps: { value: _vm.searchQuery },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchUser.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                },
              },
            }),
            _c("i", { staticClass: "fa-solid fa-magnifying-glass" }),
          ]
        ),
      ]),
      _vm._l(_vm.users, function (userId, index) {
        return _c(
          "div",
          {
            key: userId,
            staticClass: "Chatt-bar",
            class: { offset: userId === _vm.selectedUserId },
            attrs: { id: "chat-bar" },
            on: {
              click: function ($event) {
                return _vm.selectUser(userId)
              },
            },
          },
          [
            _c("div", { staticClass: "achievement-circle" }, [
              _c("div", { staticClass: "user-images" }, [
                _c("img", {
                  attrs: { src: _vm.getImage(index), alt: "user image" },
                  on: { error: _vm.handleImageError },
                }),
              ]),
            ]),
            _c(
              "span",
              {
                staticClass: "centered-text",
                staticStyle: {
                  "padding-left": "40px",
                  "font-family": "system-ui",
                },
              },
              [_vm._v(_vm._s(_vm.usernames[userId] || "Loading..."))]
            ),
            _c("i", {
              staticClass: "fa-solid fa-xmark",
              staticStyle: { color: "red" },
              on: {
                click: function ($event) {
                  return _vm.handleDeleteUser($event, userId)
                },
              },
            }),
          ]
        )
      }),
      _c("font-awesome-icon", { attrs: { icon: "icons" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }