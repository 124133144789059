// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bar-layout[data-v-6c52b046] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 12px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 80px;
}
#chat-bar[data-v-6c52b046] {
  color: black;
  border-style: solid;
  background-color: white;
  border-color: white;
  width: 100%;
  height: 70px;
  margin-top: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding-right: 80px;
}
.Chatt-bar[data-v-6c52b046] {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  padding: 0 10px;
  position: relative;
  background-color: white;
}
.Chatt-bar span[data-v-6c52b046] {
  font-size: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-wrapper[data-v-6c52b046] {
  position: relative;
  display: inline-block;
  border-radius: 40px;
  width: 400px;
}
.search-user[data-v-6c52b046] {
  border: none;
  border-radius: 40px;
  width: 400px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  outline: none;
}
.fa-magnifying-glass[data-v-6c52b046] {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2rem;
}
.fa-xmark[data-v-6c52b046] {
  right: 20px;
  position: absolute;
  font-size: 1.8rem;
}
.Chatt-bar .centered-text[data-v-6c52b046] {
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  flex-grow: 1;
}
@media only screen and (max-width: 768px) {
.username-icon[data-v-6c52b046] {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
}
#chat-bar[data-v-6c52b046] {
    color: black;
    border-style: solid;
    background-color: white;
    border-color: white;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    padding-right: 80px;
}
.fa-magnifying-glass[data-v-6c52b046] {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
}
input[data-v-6c52b046] {
    border: none;
    float: left;
    border-radius: 40px;
    width: 70%;
    height: 40px;
    text-align: center;
    font-size: 18px;
    outline: none;
}
.user-images[data-v-6c52b046] {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    /* You can style the user's image here */
    border-radius: 50%;
    /* To create a circular image */
    margin-right: 10px;
}
.user-images img[data-v-6c52b046] {
    -o-object-fit: fill;
       object-fit: fill;
    height: inherit;
    width: inherit;
}
.Chatt-bar span[data-v-6c52b046] {
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.offset[data-v-6c52b046] {
    transform: translateX(60px);
}
.fa-xmark[data-v-6c52b046] {
    right: 20px;
    position: absolute;
    font-size: 1.3rem;
}
.search-user[data-v-6c52b046] {
    border: none;
    width: 200px;
    height: 40px;
    text-align: center;
    font-size: 12px;
    outline: none;
}
.search-bar[data-v-6c52b046] {
    width: 100%;
    padding-top: 30px;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
