// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/pexels.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bar-layout[data-v-364f2a08] {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 12px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 80px;
}
.search-bar[data-v-364f2a08] {
  margin: 20px;
  padding: 0;
  width: 10%;
  /* Set the width to 100% to fit within the available space */
  text-align: center;
  /* Center the content horizontally */
  border-radius: 40px;
}
#chat-bar[data-v-364f2a08] {
  padding: 22px;
  color: black;
  border-style: solid;
  background-color: white;
  border-radius: 40px;
  border-color: white;
  width: 400px;
  height: 70px;
  margin-top: 20px;
  margin-left: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}
.Chatt-bar[data-v-364f2a08] {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  padding: 0 10px;
  position: relative;
}

/* Animation */
@keyframes slideFromLeft-data-v-364f2a08 {
from {
    transform: translateX(-100%);
}
to {
    transform: translateX(0);
}
}
.Chatt-bar[data-v-364f2a08] {
  animation: slideFromLeft-data-v-364f2a08 0.5s ease-out;
  /* Apply the animation */
}

/* Hover effect */
.Chatt-bar[data-v-364f2a08]:hover:not(.offset) {
  transform: scale(1.05);
  /* Slightly scale the element */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  /* Add a deeper shadow */
}
.Chatt-bar span[data-v-364f2a08] {
  font-size: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  /* Vertical centering of flex children */
  justify-content: center;
}
.user-images[data-v-364f2a08] {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  /* You can style the user's image here */
  border-radius: 50%;
  /* To create a circular image */
  margin-right: 10px;
}
.user-images img[data-v-364f2a08] {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-fit: fill;
     object-fit: fill;
  height: inherit;
  width: inherit;
}
.offset[data-v-364f2a08] {
  transform: translateX(100px);
}
.input-wrapper[data-v-364f2a08] {
  position: relative;
  display: inline-block;
  border-radius: 40px;
  width: 400px;
}
input[data-v-364f2a08] {
  border: none;
  border-radius: 40px;
  width: 400px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  outline: none;
  -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
}
.fa-magnifying-glass[data-v-364f2a08] {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2rem;
}
.fa-xmark[data-v-364f2a08] {
  right: 20px;
  position: absolute;
  font-size: 1.8rem;
}
.Chatt-bar .centered-text[data-v-364f2a08] {
  max-width: 60%;
  /* adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  /* adjust as needed, especially if there are other elements like icons on the right */
  flex-grow: 1;
  /* This allows it to take up all available space */
}
@media only screen and (max-width: 1300px) {
#chat-bar[data-v-364f2a08] {
    padding: 22px;
    color: black;
    border-style: solid;
    background-color: white;
    border-radius: 40px;
    border-color: white;
    width: 300px;
    height: 60px;
    margin-top: 20px;
    margin-left: 20px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}
.fa-magnifying-glass[data-v-364f2a08] {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
}
.user-images[data-v-364f2a08] {
    width: 40px;
    height: 40px;
    background-color: #ccc;
    /* You can style the user's image here */
    border-radius: 50%;
    /* To create a circular image */
    margin-right: 10px;
}
input[data-v-364f2a08] {
    border: none;
    float: left;
    border-radius: 40px;
    width: 250px;
    /* change this */
    height: 50px;
    /* change this */
    text-align: center;
    font-size: 24px;
    /* change this */
    outline: none;
    -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
    -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
}
.achievement-circle[data-v-364f2a08] {
    float: left;
    display: inline-block;
    border-radius: 50%;
    /* change this */
    margin: -15px;
    margin-inline-start: 1%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.Chatt-bar span[data-v-364f2a08] {
    font-size: 21px;
    /* change this */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.offset[data-v-364f2a08] {
    transform: translateX(70px);
}
.fa-xmark[data-v-364f2a08] {
    right: 20px;
    position: absolute;
    font-size: 1.5rem;
}
}
@media only screen and (max-width: 968px) {
#chat-bar[data-v-364f2a08] {
    padding: 22px;
    color: black;
    border-style: solid;
    background-color: white;
    border-radius: 40px;
    border-color: white;
    width: 200px;
    height: 50px;
    margin-top: 20px;
    margin-left: 20px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}
.fa-magnifying-glass[data-v-364f2a08] {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
}
.user-images[data-v-364f2a08] {
    width: 30px;
    height: 30px;
    background-color: #ccc;
    /* You can style the user's image here */
    border-radius: 50%;
    /* To create a circular image */
    margin-right: 10px;
}
input[data-v-364f2a08] {
    border: none;
    float: left;
    border-radius: 40px;
    width: 200px;
    /* change this */
    height: 40px;
    /* change this */
    text-align: center;
    font-size: 20px;
    /* change this */
    outline: none;
    -webkit-box-shadow: 0 0 10px rgb(0, 0, 0);
    -moz-box-shadow: 0 0 10px rgb(0, 0, 0);
}
.achievement-circle[data-v-364f2a08] {
    float: left;
    display: inline-block;
    border-radius: 50%;
    /* change this */
    margin: -15px;
    margin-inline-start: 1%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.Chatt-bar span[data-v-364f2a08] {
    font-size: 18px;
    /* change this */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.offset[data-v-364f2a08] {
    transform: translateX(60px);
}
.fa-xmark[data-v-364f2a08] {
    right: 20px;
    position: absolute;
    font-size: 1.3rem;
}
}
@media only screen and (max-width: 768px) {
.Chatt-bar[data-v-364f2a08] {
    display: none;
}
.search-bar[data-v-364f2a08] {
    display: none;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
