var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background-image" }, [
    _c(
      "form",
      {
        staticClass: "register-form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.register.apply(null, arguments)
          },
        },
      },
      [
        _c("h1", { staticStyle: { "font-family": "system-ui" } }, [
          _vm._v("Register"),
        ]),
        _c("div", { staticClass: "input-icon-user" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user_name,
                expression: "user_name",
              },
            ],
            staticStyle: {
              "font-size": "22px",
              "font-family": "system-ui",
              "padding-left": "5%",
            },
            attrs: { type: "text", placeholder: "Username" },
            domProps: { value: _vm.user_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.user_name = $event.target.value
              },
            },
          }),
          _c("i", { staticClass: "fa-solid fa-user" }),
        ]),
        _c("br"),
        _c("div", { staticClass: "input-icon-email" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticStyle: {
              "font-size": "22px",
              "font-family": "system-ui",
              "padding-left": "5%",
            },
            attrs: { type: "email", placeholder: "Email" },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
          _c("i", { staticClass: "fa-solid fa-envelope" }),
        ]),
        _c("br"),
        _c("div", { staticClass: "input-icon-password" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticStyle: {
              "font-size": "22px",
              "font-family": "system-ui",
              "padding-left": "5%",
            },
            attrs: { type: "password", placeholder: "Password" },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
          _c("i", { staticClass: "fa-solid fa-lock" }),
        ]),
        _c("br"),
        _c("div", { staticClass: "input-country" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedCountry,
                  expression: "selectedCountry",
                },
              ],
              staticStyle: {
                "font-size": "22px",
                "font-family": "system-ui",
                "padding-left": "5%",
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedCountry = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { disabled: "", value: "" } }, [
                _vm._v("Select a language"),
              ]),
              _vm._l(_vm.countries, function (country) {
                return _c(
                  "option",
                  { key: country, domProps: { value: country } },
                  [_vm._v(_vm._s(country))]
                )
              }),
            ],
            2
          ),
        ]),
        _c("br"),
        _c("button", { staticStyle: { "font-family": "system-ui" } }, [
          _vm._v("Sign up"),
        ]),
        _c("div", { staticClass: "login-link" }, [
          _c(
            "p",
            [
              _vm._v("Already have an account? "),
              _c("router-link", { attrs: { to: "/" } }, [_vm._v("Login")]),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }