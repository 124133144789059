var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c(
      "div",
      { staticClass: "navbar" },
      [
        _c("Navbar"),
        _c("i", {
          staticClass: "fa-solid fa-bars menu-icon",
          on: { click: _vm.toggleMenu },
        }),
        _c(
          "div",
          {
            staticClass: "side-menu",
            class: { "menu-visible": _vm.menuVisible },
          },
          [
            _c("i", {
              staticClass: "fa-solid fa-xmark x",
              on: { click: _vm.unToggleMenu },
            }),
            _c("Sidemenu", {
              attrs: { selectedUserId: _vm.selectedUserId },
              on: {
                unToggleMenu: _vm.unToggleMenu,
                "selected-user": _vm.handleSelectedUser,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "main-content" }, [
      _c(
        "div",
        { staticClass: "left-panel" },
        [
          _c("Chattbars", {
            attrs: { selectedUserId: _vm.selectedUserId },
            on: {
              "selected-user": _vm.handleSelectedUser,
              unToggleMenu: _vm.unToggleMenu,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-panel" },
        [
          _c("Chatrooms", {
            ref: "chatroomsComponent",
            attrs: { loggedInUserId: _vm.loggedInUserId },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }