// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/luke-chesser-eICUFSeirc0-unsplash.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.register-form h1[data-v-63ae9146]{
  font-size: 100px;
  color: black;
  font-size: 62px;
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.register-form[data-v-63ae9146]{
  display: flex;
  flex-direction: column;
  justify-content: center;  /* This will try to center the content vertically */
  align-items: center;      /* This will center the content horizontally */
  height: 100vh;
  margin: 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
background-size: cover;
  -webkit-box-shadow: 0 0 10px rgb(11, 9, 9);
  -moz-box-shadow: 0 0 10px rgb(0,0,0);
}
input[data-v-63ae9146]{
  border-radius: 40px;
  outline: none;
  width: 500px;
  height: 50px;
  display: grid;
  padding: .25rem;
  border: none;
}
p[data-v-63ae9146]{
    color: black;
    padding-top: 40px;
}
button[data-v-63ae9146]{
    background-color: white;
    border-radius: 10px;
    border: none;
    width: 150px;
    height: 50px;
    font-size: 22px;
    transition: all 0.2s ease;
}
button[data-v-63ae9146]:active{
  transform: scale(0.96);
}
button[data-v-63ae9146]:hover{
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.input-icon-user[data-v-63ae9146]{

    position: relative;
    display: inline-block ;
}
.input-icon-user i[data-v-63ae9146]{
    color: black;
    position: absolute;
    top: 60%;
    right: 25px;
    pointer-events: none;
    transform: translateY(-50%);
}
.input-icon-email[data-v-63ae9146]{

position: relative;
display: inline-block ;
}
.input-icon-email i[data-v-63ae9146]{
color: black;
position: absolute;
top: 60%;
right: 25px;
pointer-events: none;
transform: translateY(-50%);
}
.input-icon-password[data-v-63ae9146]{

position: relative;
display: inline-block ;
}
.input-country[data-v-63ae9146]{

position: relative;
display: inline-block ;
}
.input-icon-password i[data-v-63ae9146]{
color: black;
position: absolute;
top: 60%;
right: 25px;
pointer-events: none;
transform: translateY(-50%);
}
.input-country i[data-v-63ae9146]{
color: black;
position: absolute;
top: 60%;
right: 25px;
pointer-events: none;
transform: translateY(-50%);
}
@media only screen and (max-width: 768px) {
.register-form[data-v-63ae9146]{
  display: flex;
  flex-direction: column;
  justify-content: center;  /* This will try to center the content vertically */
  align-items: center;      /* This will center the content horizontally */
  height: 100vh;
  margin: 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
background-size: cover;
  -webkit-box-shadow: 0 0 10px rgb(11, 9, 9);
  -moz-box-shadow: 0 0 10px rgb(0,0,0);
}
input[data-v-63ae9146]{
  border-radius: 40px;
  outline: none;
  width: 200px;
  height: 50px;
  display: block;
  border: none;
}
p[data-v-63ae9146]{
    color: black;
}
button[data-v-63ae9146] {
  display: block;
  background-color: white;
  border-radius: 10px;
  border: none;
  width: 120px;
  height: 40px;
  font-size: 18px;
  transition: all 0.2s ease;
  text-align: center;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
