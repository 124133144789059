var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "navbar" } }, [
    _c(
      "div",
      { staticClass: "topnav" },
      [
        _c("div", { staticClass: "icon-user" }, [
          _c("i", { staticClass: "fa-solid fa-user user" }),
          _c("p", [_vm._v(_vm._s(_vm.username))]),
        ]),
        _c(
          "b-dropdown",
          {
            staticClass: "icon-settings",
            attrs: { variant: "primary" },
            scopedSlots: _vm._u([
              {
                key: "button-content",
                fn: function () {
                  return [
                    _c("i", {
                      staticClass: "fa-solid fa-gear",
                      attrs: { "aria-label": "Settings" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("b-dropdown-item", { on: { click: _vm.toggleLog } }, [
              _vm._v("Logout"),
            ]),
            _c("b-dropdown-item", { on: { click: _vm.toggleUse } }, [
              _vm._v("Change Username"),
            ]),
            _c("b-dropdown-item", { on: { click: _vm.togglePas } }, [
              _vm._v("Change Password"),
            ]),
            _c("b-dropdown-item", { on: { click: _vm.togglePut } }, [
              _vm._v("Change Email"),
            ]),
            _vm.isAdmin
              ? _c("b-dropdown-item", { on: { click: _vm.toggleDeleteAll } }, [
                  _vm._v("Delete all messages"),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "popup-content" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.changeUsername,
              expression: "changeUsername",
            },
          ],
          staticClass: "popup",
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.updateUsername(_vm.new_user)
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa-solid fa-xmark",
                staticStyle: { color: "black", "font-size": "22px" },
                on: {
                  click: function ($event) {
                    return _vm.toggle()
                  },
                },
              }),
              _c("label", { attrs: { for: "username" } }, [
                _vm._v("Enter new username:"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.new_user,
                    expression: "new_user",
                  },
                ],
                attrs: { type: "text", autocomplete: "off" },
                domProps: { value: _vm.new_user },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.new_user = $event.target.value
                  },
                },
              }),
              _c(
                "button",
                {
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      return _vm.updateUsername(_vm.new_user)
                    },
                  },
                },
                [_vm._v("Submit")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.changePassword,
              expression: "changePassword",
            },
          ],
          staticClass: "popup",
        },
        [
          _c("form", [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v("Enter new password:"),
            ]),
            _c("i", {
              staticClass: "fa-solid fa-xmark",
              staticStyle: { color: "black", "font-size": "22px" },
              on: {
                click: function ($event) {
                  return _vm.toggle()
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.new_pass,
                  expression: "new_pass",
                },
              ],
              attrs: { type: "text", autocomplete: "off" },
              domProps: { value: _vm.new_pass },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.new_pass = $event.target.value
                },
              },
            }),
            _c(
              "button",
              {
                staticStyle: { color: "white" },
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    return _vm.updatePassword(_vm.new_pass)
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.put,
              expression: "put",
            },
          ],
          staticClass: "popup",
          attrs: { id: "put" },
        },
        [
          _c("form", [
            _c("br"),
            _c("label", { attrs: { for: "put" } }, [
              _vm._v("Enter new details: "),
            ]),
            _c("br"),
            _c("i", {
              staticClass: "fa-solid fa-xmark",
              staticStyle: { color: "black", "font-size": "22px" },
              on: { click: _vm.toggle },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.new_user,
                  expression: "new_user",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "Username",
                autocomplete: "off",
              },
              domProps: { value: _vm.new_user },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.new_user = $event.target.value
                },
              },
            }),
            _c("br"),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.new_pass,
                  expression: "new_pass",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "Password",
                autocomplete: "off",
              },
              domProps: { value: _vm.new_pass },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.new_pass = $event.target.value
                },
              },
            }),
            _c("br"),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.new_email,
                  expression: "new_email",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "Email",
                autocomplete: "off",
              },
              domProps: { value: _vm.new_email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.new_email = $event.target.value
                },
              },
            }),
            _c("br"),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.put_user(
                      _vm.new_user,
                      _vm.new_pass,
                      _vm.new_email
                    )
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.logout,
              expression: "logout",
            },
          ],
          staticClass: "popup",
        },
        [
          _c("form", [
            _c("i", {
              staticClass: "fa-solid fa-xmark",
              staticStyle: { color: "black", "font-size": "22px" },
              on: {
                click: function ($event) {
                  return _vm.toggle()
                },
              },
            }),
            _c(
              "label",
              { staticClass: "logout-text", attrs: { for: "logout" } },
              [_vm._v("Are you sure?")]
            ),
            _c("br"),
            _c("br"),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.logoff()
                  },
                },
              },
              [_vm._v("Logout")]
            ),
          ]),
        ]
      ),
      _vm.isAdmin
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.deleteAllMessagesPopup,
                  expression: "deleteAllMessagesPopup",
                },
              ],
              staticClass: "popup",
            },
            [
              _c("form", [
                _c("i", {
                  staticClass: "fa-solid fa-xmark",
                  staticStyle: { color: "black", "font-size": "22px" },
                  on: {
                    click: function ($event) {
                      return _vm.toggle()
                    },
                  },
                }),
                _c(
                  "label",
                  { staticClass: "logout-text", attrs: { for: "logout" } },
                  [_vm._v("Are you sure you want to delete all messages?")]
                ),
                _c("br"),
                _c("br"),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.deleteAllMessages()
                      },
                    },
                  },
                  [_vm._v("Delete All")]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }