var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "login-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.login.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "background-image" }, [
        _c("h1", { staticStyle: { "font-family": "system-ui" } }, [
          _vm._v("Login"),
        ]),
        _c("div", { staticClass: "input-icon-user" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user_name,
                expression: "user_name",
              },
            ],
            staticStyle: {
              "font-size": "22px",
              "font-family": "system-ui",
              "padding-left": "5%",
            },
            attrs: { type: "text", placeholder: "Username" },
            domProps: { value: _vm.user_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.user_name = $event.target.value
              },
            },
          }),
          _c("i", { staticClass: "fa-solid fa-user" }),
        ]),
        _c("br"),
        _c("div", { staticClass: "input-icon-password" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticStyle: {
              "font-size": "22px",
              "font-family": "system-ui",
              "padding-left": "5%",
            },
            attrs: { type: "password", placeholder: "Password" },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
          _c("i", { staticClass: "fa-solid fa-lock" }),
        ]),
        _c("br"),
        _c(
          "button",
          {
            staticStyle: { "font-family": "system-ui" },
            attrs: { type: "submit" },
          },
          [_vm._v("Login")]
        ),
        _c("div", { staticClass: "register-link" }, [
          _c(
            "p",
            { staticClass: "register" },
            [
              _vm._v("Don't have an account? "),
              _c("router-link", { attrs: { to: "/register" } }, [
                _vm._v("Register"),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }