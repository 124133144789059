// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/luke-chesser-eICUFSeirc0-unsplash.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.register-link[data-v-26084dc2] {
    margin-top: 20px;
}
.login-form h1[data-v-26084dc2]{
  font-size: 100;
  color: black;
  padding-bottom: 50px;
  font-size: 62px;
}
.login-form[data-v-26084dc2]{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  height: 100vh;
  margin: 0;

  -webkit-box-shadow: 0 0 10px rgb(0,0,0);
  -moz-box-shadow: 0 0 10px rgb(0,0,0);
}
input[data-v-26084dc2]{
  border-radius: 40px;
  outline: none;
  width: 500px;
  height: 50px;
  display: grid;
  padding: .25rem;
  margin-top: 4%;
  border: none;
}
.register[data-v-26084dc2]{
    padding-top: 40px;
    color: black;
}
button[data-v-26084dc2] {
  display: block;
  margin: 10% auto 0 auto;
  background-color: white;
  border-radius: 10px;
  border: none;
  width: 150px;
  height: 50px;
  font-size: 22px;
  transition: all 0.2s ease;
  text-align: center;
}
button[data-v-26084dc2]:active{
  transform: scale(0.96);
}
button[data-v-26084dc2]:hover{
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.input-icon-user[data-v-26084dc2]{

position: relative;
display: inline-block ;
}
.input-icon-user i[data-v-26084dc2]{
    color: black;
    position: absolute;
    top: 70%;
    right: 25px;
    pointer-events: none;
    transform: translateY(-50%);
}
.input-icon-password[data-v-26084dc2]{

position: relative;
display: inline-block ;
}
.input-icon-password i[data-v-26084dc2]{
color: black;
position: absolute;
top: 70%;
right: 25px;
pointer-events: none;
transform: translateY(-50%);
}
@media only screen and (max-width: 768px) {
.login-form[data-v-26084dc2]{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  height: 100vh;
  margin: 0;

  -webkit-box-shadow: 0 0 10px rgb(0,0,0);
  -moz-box-shadow: 0 0 10px rgb(0,0,0);
}
input[data-v-26084dc2]{
  border-radius: 40px;
  outline: none;
  width: 200px;
  height: 50px;
  display: grid;
  padding: .25rem;
  margin-top: 4%;
  border: none;
}
button[data-v-26084dc2] {
  display: block;
  margin: 10% auto 0 auto;
  background-color: white;
  border-radius: 10px;
  border: none;
  width: 120px;
  height: 40px;
  font-size: 18px;
  transition: all 0.2s ease;
  text-align: center;
}
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
