// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  margin-top: -45px;  /* adjust this value to raise or lower the icon */
}
[v-cloak] {
  display: none;
}
.dashboard {
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.navbar {
  display: flex;  /* Added Flexbox */
  justify-content: space-between;  /* To push Navbar and icon to opposite ends */
  align-items: center;  /* To vertically align items */
  color: black;
  padding: 1rem;
  position: absolute;
  height: 8vh;
  top: 0;
  z-index: 2;
  overflow-y: visible;
}
.main-content {
  display: flex;
  flex: 1;
  padding-top: 0.5rem;
  position: relative;
  height: 92vh;
}
.left-panel {
  flex: 0 0 30%;
  background-color: #f0f0f0;
  overflow-y: auto;
}
.right-panel {
  flex: 1;
  background-color: #ffffff;
  overflow-y: auto;
}
.side-menu {
 display: none;
}
@media only screen and (max-width: 768px){
.menu-icon {
    display: inline-block;
}
.side-menu {

    background-color: white; /* or any other solid color */
    z-index: 1000; /* Ensure it's above other elements */
will-change: transform;
    display: block;
  position: fixed;
  top: 0;
  left: -300px; /* this assumes a sidebar width of 300px */
  height: 100%;
  width: 300px;
  background: white; /* or any color you prefer */
  overflow-y: auto; /* for scrolling if the content overflows */
  transition: left 0.1s;
  box-shadow: none !important;
}
.menu-visible {
  left: 0;
}
.x{
  left: -300px;
  font-size: 30px;
  padding-top: 20px;
padding-left: 240px;
z-index: 100;
}
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
